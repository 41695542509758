import ApiService from "./api.service";

const RequestService = {
    GetList(data){
        return ApiService.post(`/Request/GetList`,data)
    },
    Get(id){
        return ApiService.get(`/Request/Get/${id}`)
    },
    DownloadBasicFile(id){
        return ApiService.print(`/Request/DownloadBasicFile/${id}`)
    },
    DownloadOrderFile(id){
        return ApiService.print(`/Request/DownloadOrderFile/${id}`)
    }
}
export default RequestService